import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import { getStoredCompanyInfo } from 'helpers/storageHelper';

export const FETCH_FREEWIE_REQUEST = 'FETCH_FREEWIE_REQUEST';
export const FETCH_FREEWIE_SUCCESS = 'FETCH_FREEWIE_SUCCESS';
export const FETCH_FREEWIE_ERROR = 'FETCH_FREEWIE_ERROR';

// --Freewie----------------
export const fetchFreewieOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_FREEWIE_REQUEST, FETCH_FREEWIE_SUCCESS, FETCH_FREEWIE_ERROR],
      callAPI: () => ApiClient.get('network/Freewie/GetSelectList'),
      payload: {},
    }),
  );
};

const companyInfo = getStoredCompanyInfo();
const initialState = {
  payload: {},
  data: {
    info: companyInfo,
  },
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_FREEWIE_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_FREEWIE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };
    
    case FETCH_FREEWIE_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
