import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_PROMOTION_REQUEST = 'FETCH_PROMOTION_REQUEST';
export const FETCH_PROMOTION_SUCCESS = 'FETCH_PROMOTION_SUCCESS';
export const FETCH_PROMOTION_ERROR = 'FETCH_PROMOTION_ERROR';

export const FETCH_PROMOTION_OPTIONS_REQUEST =
  'FETCH_PROMOTION_OPTIONS_REQUEST';
export const FETCH_PROMOTION_OPTIONS_SUCCESS =
  'FETCH_PROMOTION_OPTIONS_SUCCESS';
export const FETCH_PROMOTION_OPTIONS_ERROR =
  'FETCH_PROMOTION_OPTIONS_ERROR';

export const GET_PROMOTION_DETAIL_REQUEST =
  'GET_PROMOTION_DETAIL_REQUEST';
export const GET_PROMOTION_DETAIL_SUCCESS =
  'GET_PROMOTION_DETAIL_SUCCESS';
export const GET_PROMOTION_DETAIL_ERROR = 'GET_PROMOTION_DETAIL_ERROR';

export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_ERROR = 'CREATE_PROMOTION_ERROR';

export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_ERROR = 'UPDATE_PROMOTION_ERROR';

export const DELETE_PROMOTION_REQUEST = 'DELETE_PROMOTION_REQUEST';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_ERROR = 'DELETE_PROMOTION_ERROR';

export const fetchPromotion = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PROMOTION_REQUEST,
        FETCH_PROMOTION_SUCCESS,
        FETCH_PROMOTION_ERROR,
      ],
      callAPI: () => ApiClient.post('product/Promotion/SearchMaster', data),
      payload: { data },
    }),
  );
};

export const getPromotionDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_PROMOTION_DETAIL_REQUEST,
        GET_PROMOTION_DETAIL_SUCCESS,
        GET_PROMOTION_DETAIL_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/Promotion/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createPromotion = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        CREATE_PROMOTION_REQUEST,
        CREATE_PROMOTION_SUCCESS,
        CREATE_PROMOTION_ERROR,
      ],
      callAPI: () => ApiClient.post('product/Promotion', data),
      payload: { data, formId },
    }),
  );
};

export const updatePromotion = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        UPDATE_PROMOTION_REQUEST,
        UPDATE_PROMOTION_SUCCESS,
        UPDATE_PROMOTION_ERROR,
      ],
      callAPI: () => ApiClient.put(`product/Promotion/Update/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deletePromotion = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        DELETE_PROMOTION_REQUEST,
        DELETE_PROMOTION_SUCCESS,
        DELETE_PROMOTION_ERROR,
      ],
      callAPI: () => ApiClient.delete(`product/Promotion/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_PROMOTION_REQUEST:
    case FETCH_PROMOTION_OPTIONS_REQUEST:
    case GET_PROMOTION_DETAIL_REQUEST:
    case CREATE_PROMOTION_REQUEST:
    case UPDATE_PROMOTION_REQUEST:
    case DELETE_PROMOTION_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_PROMOTION_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_PROMOTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case GET_PROMOTION_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_PROMOTION_SUCCESS:
    case UPDATE_PROMOTION_SUCCESS:
    case DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_PROMOTION_ERROR:
    case FETCH_PROMOTION_OPTIONS_ERROR:
    case GET_PROMOTION_DETAIL_ERROR:
    case CREATE_PROMOTION_ERROR:
    case UPDATE_PROMOTION_ERROR:
    case DELETE_PROMOTION_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
