import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const GET_PRODUCT_PRICE_OPTIONS_REQUEST =
  'GET_PRODUCT_PRICE_OPTIONS_REQUEST';
export const GET_PRODUCT_PRICE_OPTIONS_SUCCESS =
  'GET_PRODUCT_PRICE_OPTIONS_SUCCESS';
export const GET_PRODUCT_PRICE_OPTIONS_ERROR = 
  'GET_PRODUCT_PRICE_OPTIONS_ERROR';


export const getProductPriceOptions = (productId, variantId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_PRODUCT_PRICE_OPTIONS_REQUEST,
        GET_PRODUCT_PRICE_OPTIONS_SUCCESS,
        GET_PRODUCT_PRICE_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/ProductPrice/GetSelectList/${productId}/${variantId}`),
      payload: { data: { productId, variantId } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case GET_PRODUCT_PRICE_OPTIONS_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case GET_PRODUCT_PRICE_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          options: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_PRODUCT_PRICE_OPTIONS_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
