import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';

export const FETCH_MORE_DIRECTORY_REQUEST = 'FETCH_MORE_DIRECTORY_REQUEST';
export const FETCH_MORE_DIRECTORY_SUCCESS = 'FETCH_MORE_DIRECTORY_SUCCESS';
export const FETCH_MORE_DIRECTORY_ERROR = 'FETCH_MORE_DIRECTORY_ERROR';


export const GET_MORE_DIRECTORY_DETAIL_REQUEST =
    'GET_MORE_DIRECTORY_DETAIL_REQUEST';
export const GET_MORE_DIRECTORY_DETAIL_SUCCESS =
    'GET_MORE_DIRECTORY_DETAIL_SUCCESS';
export const GET_MORE_DIRECTORY_DETAIL_ERROR = 'GET_MORE_DIRECTORY_DETAIL_ERROR';

export const CREATE_MORE_DIRECTORY_REQUEST = 'CREATE_MORE_DIRECTORY_REQUEST';
export const CREATE_MORE_DIRECTORY_SUCCESS = 'CREATE_MORE_DIRECTORY_SUCCESS';
export const CREATE_MORE_DIRECTORY_ERROR = 'CREATE_MORE_DIRECTORY_ERROR';

export const UPDATE_MORE_DIRECTORY_REQUEST = 'UPDATE_MORE_DIRECTORY_REQUEST';
export const UPDATE_MORE_DIRECTORY_SUCCESS = 'UPDATE_MORE_DIRECTORY_SUCCESS';
export const UPDATE_MORE_DIRECTORY_ERROR = 'UPDATE_MORE_DIRECTORY_ERROR';

export const DELETE_MORE_DIRECTORY_REQUEST = 'DELETE_MORE_DIRECTORY_REQUEST';
export const DELETE_MORE_DIRECTORY_SUCCESS = 'DELETE_MORE_DIRECTORY_SUCCESS';
export const DELETE_MORE_DIRECTORY_ERROR = 'DELETE_MORE_DIRECTORY_ERROR';

export const fetchMoreDirectory = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_MORE_DIRECTORY_REQUEST,
                FETCH_MORE_DIRECTORY_SUCCESS,
                FETCH_MORE_DIRECTORY_ERROR,
            ],
            callAPI: () => ApiClient.post('network/MoreDirectory/Search', data),
            payload: { data },
        }),
    );
};

export const getMoreDirectoryDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_MORE_DIRECTORY_DETAIL_REQUEST,
                GET_MORE_DIRECTORY_DETAIL_SUCCESS,
                GET_MORE_DIRECTORY_DETAIL_ERROR,
            ],
            callAPI: () => ApiClient.get(`network/MoreDirectory/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const createMoreDirectory = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                CREATE_MORE_DIRECTORY_REQUEST,
                CREATE_MORE_DIRECTORY_SUCCESS,
                CREATE_MORE_DIRECTORY_ERROR,
            ],
            callAPI: () => ApiClient.post('network/MoreDirectory', data),
            payload: { data, formId },
        }),
    );
};


export const updateMoreDirectory = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                UPDATE_MORE_DIRECTORY_REQUEST,
                UPDATE_MORE_DIRECTORY_SUCCESS,
                UPDATE_MORE_DIRECTORY_ERROR,
            ],
            callAPI: () => ApiClient.put(`network/MoreDirectory/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const deleteMoreDirectory = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                DELETE_MORE_DIRECTORY_REQUEST,
                DELETE_MORE_DIRECTORY_SUCCESS,
                DELETE_MORE_DIRECTORY_ERROR,
            ],
            callAPI: () => ApiClient.delete(`network/MoreDirectory/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_MORE_DIRECTORY_REQUEST:
        case GET_MORE_DIRECTORY_DETAIL_REQUEST:
        case CREATE_MORE_DIRECTORY_REQUEST:
        case UPDATE_MORE_DIRECTORY_REQUEST:
        case DELETE_MORE_DIRECTORY_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_MORE_DIRECTORY_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_MORE_DIRECTORY_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_MORE_DIRECTORY_SUCCESS:
        case UPDATE_MORE_DIRECTORY_SUCCESS:
        case DELETE_MORE_DIRECTORY_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_MORE_DIRECTORY_ERROR:
        case GET_MORE_DIRECTORY_DETAIL_ERROR:
        case CREATE_MORE_DIRECTORY_ERROR:
        case UPDATE_MORE_DIRECTORY_ERROR:
        case DELETE_MORE_DIRECTORY_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
