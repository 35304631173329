import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import id from 'date-fns/locale/id/index';

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR';

export const FETCH_INVOICE_DETAIL_REQUEST = 'FETCH_INVOICE_DETAIL_REQUEST';
export const FETCH_INVOICE_DETAIL_SUCCESS = 'FETCH_INVOICE_DETAIL_SUCCESS';
export const FETCH_INVOICE_DETAIL_ERROR = 'FETCH_INVOICE_DETAIL_ERROR';

export const FETCH_EMAIL_LOG_REQUEST = 'FETCH_EMAIL_LOG_REQUEST';
export const FETCH_EMAIL_LOG_SUCCESS = 'FETCH_EMAIL_LOG_SUCCESS';
export const FETCH_EMAIL_LOG_ERROR = 'FETCH_EMAIL_LOG_ERROR';

export const GET_INVOICE_DETAIL_REQUEST = 'GET_INVOICE_DETAIL_REQUEST';
export const GET_INVOICE_DETAIL_SUCCESS = 'GET_INVOICE_DETAIL_SUCCESS';
export const GET_INVOICE_DETAIL_ERROR = 'GET_INVOICE_DETAIL_ERROR';

export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR';

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'DELETE_INVOICE_ERROR';

export const VOID_INVOICE_REQUEST = 'VOID_INVOICE_REQUEST';
export const VOID_INVOICE_SUCCESS = 'VOID_INVOICE_SUCCESS';
export const VOID_INVOICE_ERROR = 'VOID_INVOICE_ERROR';

export const COMPLETE_INVOICE_REQUEST = 'COMPLETE_INVOICE_REQUEST';
export const COMPLETE_INVOICE_SUCCESS = 'COMPLETE_INVOICE_SUCCESS';
export const COMPLETE_INVOICE_ERROR = 'COMPLETE_INVOICE_ERROR';

export const GENERATE_INVOICE_PDF_REQUEST = 'GENERATE_INVOICE_PDF_REQUEST';
export const GENERATE_INVOICE_PDF_SUCCESS = 'GENERATE_INVOICE_PDF_SUCCESS';
export const GENERATE_INVOICE_PDF_ERROR = 'GENERATE_INVOICE_PDF_ERROR';

export const SEND_MULTIPLE_CUSTOMER_EMAIL_REQUEST = 'SEND_MULTIPLE_CUSTOMER_EMAIL_REQUEST';
export const SEND_MULTIPLE_CUSTOMER_EMAIL_SUCCESS = 'SEND_MULTIPLE_CUSTOMER_EMAIL_SUCCESS';
export const SEND_MULTIPLE_CUSTOMER_EMAIL_ERROR = 'SEND_MULTIPLE_CUSTOMER_EMAIL_ERROR';

export const SEND_CUSTOMER_EMAIL_REQUEST = 'SEND_CUSTOMER_EMAIL_REQUEST';
export const SEND_CUSTOMER_EMAIL_SUCCESS = 'SEND_CUSTOMER_EMAIL_SUCCESS';
export const SEND_CUSTOMER_EMAIL_ERROR = 'SEND_CUSTOMER_EMAIL_ERROR';

export const EXPORT_EXCEL_REQUEST = 'EXPORT_EXCEL_REQUEST';
export const EXPORT_EXCEL_SUCCESS = 'EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_ERROR = 'EXPORT_EXCEL_ERROR';

export const CREATE_INVOICECANCELLED_REQUEST = 'CREATE_INVOICECANCELLED_REQUEST';
export const CREATE_INVOICECANCELLED_SUCCESS = 'CREATE_INVOICECANCELLED_SUCCESS';
export const CREATE_INVOICECANCELLED_ERROR = 'CREATE_INVOICECANCELLED_ERROR';

export const FETCH_INVOICECANCELLED_REQUEST = 'FETCH_INVOICECANCELLED_REQUEST';
export const FETCH_INVOICECANCELLED_SUCCESS = 'FETCH_INVOICECANCELLED_SUCCESS';
export const FETCH_INVOICECANCELLED_ERROR = 'FETCH_INVOICECANCELLED_ERROR';

export const GET_INVOICE_CANCELLED_DETAIL_REQUEST = 'GET_INVOICE_CANCELLED_DETAIL_REQUEST';
export const GET_INVOICE_CANCELLED_DETAIL_SUCCESS = 'GET_INVOICE_CANCELLED_DETAIL_SUCCESS';
export const GET_INVOICE_CANCELLED_DETAIL_ERROR = 'GET_INVOICE_CANCELLED_DETAIL_ERROR';

export const UPDATE_INVOICE_CANCELLED_REQUEST = 'UPDATE_INVOICE_CANCELLED_REQUEST';
export const UPDATE_INVOICE_CANCELLED_SUCCESS = 'UPDATE_INVOICE_CANCELLED_SUCCESS';
export const UPDATE_INVOICE_CANCELLED_ERROR = 'UPDATE_INVOICE_CANCELLED_ERROR';

export const DELETE_INVOICE_CANCELLED_REQUEST = 'DELETE_INVOICE_CANCELLED_REQUEST';
export const DELETE_INVOICE_CANCELLED_SUCCESS = 'DELETE_INVOICE_CANCELLED_SUCCESS';
export const DELETE_INVOICE_CANCELLED_ERROR = 'DELETE_INVOICE_CANCELLED_ERROR';

export const COMPLETE_INVOICE_CANCELLED_REQUEST = 'COMPLETE_INVOICE_CANCELLED_REQUEST';
export const COMPLETE_INVOICE_CANCELLED_SUCCESS = 'COMPLETE_INVOICE_CANCELLED_SUCCESS';
export const COMPLETE_INVOICE_CANCELLED_ERROR = 'COMPLETE_INVOICE_CANCELLED_ERROR';

export const fetchInvoices = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_ERROR],
      callAPI: () => ApiClient.post('payment/Invoices/Search', data),
      payload: { data },
    }),
  );
};

export const fetchInvoiceDetails = data => dispatch => {
  if (data.filterColumns != null && data.filterColumns.length > 0) {
    data.filterColumns = data.filterColumns.map(filterColumn => ({
      ...filterColumn,
      id: filterColumn.id.includes('esimid') || filterColumn.id.includes('latestSentDate') || filterColumn.id.includes('emailSent') ? filterColumn.id : "invoice." + filterColumn.id,
    }));
  }

  if (data.sortColumns != null && data.sortColumns.length > 0) {
    data.sortColumns = data.sortColumns.map(sortColumn => ({
      ...sortColumn,
      id: sortColumn.id.includes('esimid') || sortColumn.id.includes('latestSentDate') || sortColumn.id.includes('emailSent') ? sortColumn.id : "invoice." + sortColumn.id,
    }));
  }

  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_INVOICE_DETAIL_REQUEST, FETCH_INVOICE_DETAIL_SUCCESS, FETCH_INVOICE_DETAIL_ERROR],
      callAPI: () => ApiClient.post('payment/Invoices/SearchInvoiceDetail', data),
      payload: { data },
    }),
  );
};

export const fetchEmailLog = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_EMAIL_LOG_REQUEST, FETCH_EMAIL_LOG_SUCCESS, FETCH_EMAIL_LOG_ERROR],
      callAPI: () => ApiClient.post('network/EmailLog/Search', data),
      payload: { data },
    }),
  );
};

export const getInvoiceDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_INVOICE_DETAIL_REQUEST, GET_INVOICE_DETAIL_SUCCESS, GET_INVOICE_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`payment/Invoices/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createInvoice = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_INVOICE_REQUEST, CREATE_INVOICE_SUCCESS, CREATE_INVOICE_ERROR],
      callAPI: () => ApiClient.post('payment/Invoices', data),
      payload: { data, formId },
    }),
  );
};

export const updateInvoice = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_INVOICE_REQUEST, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_ERROR],
      callAPI: () => ApiClient.put(`payment/Invoices/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteInvoice = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_INVOICE_REQUEST, DELETE_INVOICE_SUCCESS, DELETE_INVOICE_ERROR],
      callAPI: () => ApiClient.delete(`payment/Invoices/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const voidInvoice = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [VOID_INVOICE_REQUEST, VOID_INVOICE_SUCCESS, VOID_INVOICE_ERROR],
      callAPI: () => ApiClient.put(`payment/Invoices/Complete/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const completeInvoice = (data) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [COMPLETE_INVOICE_REQUEST, COMPLETE_INVOICE_SUCCESS, COMPLETE_INVOICE_ERROR],
      callAPI: () => ApiClient.put(`payment/Invoices/Complete`, data),
      payload: { data: data },
    }),
  );
};

export const generateInvoicePdf = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GENERATE_INVOICE_PDF_REQUEST, GENERATE_INVOICE_PDF_SUCCESS, GENERATE_INVOICE_PDF_ERROR],
      callAPI: () => ApiClient.post(`payment/Invoices/CreatePDF/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const onVoidPaydollarPayment = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GENERATE_INVOICE_PDF_REQUEST, GENERATE_INVOICE_PDF_SUCCESS, GENERATE_INVOICE_PDF_ERROR],
      callAPI: () => ApiClient.post(`payment/Invoices/PaydollarRefund/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createInvoiceCancelled = (data) => dispatch => {
  console.log(data);
  return Promise.resolve(dispatch).then(() =>
    dispatch({
      types: [CREATE_INVOICECANCELLED_REQUEST, CREATE_INVOICECANCELLED_SUCCESS, CREATE_INVOICECANCELLED_ERROR],
      callAPI: () => ApiClient.post('payment/InvoiceCancelled', data),
      payload: { data },
    }),
  );
};

export const fetchInvoiceCancelled = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_INVOICECANCELLED_REQUEST, FETCH_INVOICECANCELLED_SUCCESS, FETCH_INVOICECANCELLED_ERROR],
      callAPI: () => ApiClient.post('payment/InvoiceCancelled/Search', data),
      payload: { data },
    }),
  );
};

export const getInvoiceCancelledDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_INVOICE_CANCELLED_DETAIL_REQUEST, GET_INVOICE_CANCELLED_DETAIL_SUCCESS, GET_INVOICE_CANCELLED_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`payment/InvoiceCancelled/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const updateInvoiceCancelled = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_INVOICE_CANCELLED_REQUEST, UPDATE_INVOICE_CANCELLED_SUCCESS, UPDATE_INVOICE_CANCELLED_ERROR],
      callAPI: () => ApiClient.put(`payment/InvoiceCancelled/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteInvoiceCancelled = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_INVOICE_CANCELLED_REQUEST, DELETE_INVOICE_CANCELLED_SUCCESS, DELETE_INVOICE_CANCELLED_ERROR],
      callAPI: () => ApiClient.delete(`payment/InvoiceCancelled/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const completeInvoiceCancelled = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [COMPLETE_INVOICE_CANCELLED_REQUEST, COMPLETE_INVOICE_CANCELLED_SUCCESS, COMPLETE_INVOICE_CANCELLED_ERROR],
      callAPI: () => ApiClient.put(`payment/InvoiceCancelled/Complete/${id}`),
    }),
  );
};

export const generateInvoicePdfPoId = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GENERATE_INVOICE_PDF_REQUEST, GENERATE_INVOICE_PDF_SUCCESS, GENERATE_INVOICE_PDF_ERROR],
      callAPI: () => ApiClient.post(`payment/Invoices/CreatePOHeaderPDF/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const sendCustomerEmail = (data) => dispatch => {
  console.log(data);
  return Promise.resolve().then(() =>
    dispatch({
      types: [SEND_MULTIPLE_CUSTOMER_EMAIL_REQUEST, SEND_MULTIPLE_CUSTOMER_EMAIL_SUCCESS, SEND_MULTIPLE_CUSTOMER_EMAIL_ERROR],
      callAPI: () => ApiClient.post(`network/Freewie/SendCustomerEmail`, data),
      payload: { data: data },
    }),
  );
};

export const sendPOCustomerEmail = (id, smtpId, email) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [SEND_CUSTOMER_EMAIL_REQUEST, SEND_CUSTOMER_EMAIL_SUCCESS, SEND_CUSTOMER_EMAIL_ERROR],
      callAPI: () => ApiClient.post(`network/Freewie/SendPOCustomerEmail/${id}/${smtpId}/${email}`),
      payload: { data: { id, smtpId } },
    }),
  );
};

export const downloadFrewiePdf = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GENERATE_INVOICE_PDF_REQUEST, GENERATE_INVOICE_PDF_SUCCESS, GENERATE_INVOICE_PDF_ERROR],
      callAPI: () => ApiClient.post(`network/Freewie/CreatePDF/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const exportExcel = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [EXPORT_EXCEL_REQUEST, EXPORT_EXCEL_SUCCESS, EXPORT_EXCEL_ERROR],
      callAPI: () => ApiClient.post('payment/Invoices/ExportExcel', data, {
        responseType: 'blob'
      }),
      payload: { data }
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_INVOICES_REQUEST:
    case FETCH_INVOICE_DETAIL_REQUEST:
    case FETCH_EMAIL_LOG_REQUEST:
    case GET_INVOICE_DETAIL_REQUEST:
    case CREATE_INVOICE_REQUEST:
    case UPDATE_INVOICE_REQUEST:
    case DELETE_INVOICE_REQUEST:
    case VOID_INVOICE_REQUEST:
    case COMPLETE_INVOICE_REQUEST:
    case GENERATE_INVOICE_PDF_REQUEST:
    case SEND_MULTIPLE_CUSTOMER_EMAIL_REQUEST:
    case SEND_CUSTOMER_EMAIL_REQUEST:
    case EXPORT_EXCEL_REQUEST:
    case CREATE_INVOICECANCELLED_REQUEST:
    case GET_INVOICE_CANCELLED_DETAIL_REQUEST:
    case DELETE_INVOICE_CANCELLED_REQUEST:
    case COMPLETE_INVOICE_CANCELLED_REQUEST:
    case UPDATE_INVOICE_CANCELLED_REQUEST:
    case FETCH_INVOICECANCELLED_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };


    case FETCH_EMAIL_LOG_SUCCESS:
    case FETCH_INVOICECANCELLED_SUCCESS:
    case FETCH_INVOICE_DETAIL_SUCCESS:
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_INVOICE_DETAIL_SUCCESS:
    case GET_INVOICE_CANCELLED_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_INVOICE_SUCCESS:
    case UPDATE_INVOICE_SUCCESS:
    case DELETE_INVOICE_SUCCESS:
    case VOID_INVOICE_SUCCESS:
    case COMPLETE_INVOICE_SUCCESS:
    case SEND_MULTIPLE_CUSTOMER_EMAIL_SUCCESS:
    case SEND_CUSTOMER_EMAIL_SUCCESS:
    case CREATE_INVOICECANCELLED_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case GENERATE_INVOICE_PDF_SUCCESS:
      return {
        data: {
          ...state.data,
          item: {
            ...state.data.item,
            url: response.data.data,
          },
        },
        response,
        successMessage,
      };

    case EXPORT_EXCEL_SUCCESS:
      return {
        ...state,
        blob: response.data,
        successMessage: 'Excel file exported successfully',
      };

    case FETCH_INVOICES_ERROR:
    case FETCH_INVOICE_DETAIL_ERROR:
    case FETCH_EMAIL_LOG_ERROR:
    case GET_INVOICE_DETAIL_ERROR:
    case CREATE_INVOICE_ERROR:
    case UPDATE_INVOICE_ERROR:
    case DELETE_INVOICE_ERROR:
    case VOID_INVOICE_ERROR:
    case COMPLETE_INVOICE_ERROR:
    case GENERATE_INVOICE_PDF_ERROR:
    case SEND_MULTIPLE_CUSTOMER_EMAIL_ERROR:
    case SEND_CUSTOMER_EMAIL_ERROR:
    case CREATE_INVOICECANCELLED_ERROR:
    case UPDATE_INVOICE_CANCELLED_ERROR:
    case DELETE_INVOICE_CANCELLED_ERROR:
    case COMPLETE_INVOICE_CANCELLED_ERROR:
    case FETCH_INVOICECANCELLED_ERROR:
    case EXPORT_EXCEL_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
