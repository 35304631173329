import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import account, { LOGOUT_SUCCESS } from './Account';
import admin from './admin/Admin';
import modal from './Modal';
import company from './network/Company';
import uom from './network/UOM';
import currency from './network/Currency';
import paymentTerm from './payment/PaymentTerm';
import tax from './network/Tax';
import purchaseSetup from './network/PurchaseSetup';
import purchaseOrder from './network/PurchaseOrder';
import saleOrder from './network/SaleOrder';
import invitation from './network/Invitation';
import moreDirectory from './network/MoreDirectory';
import offerGroup from './network/OfferGroup';
import partnerOffer from './network/PartnerOffer';

import log from './Log';

import file from './File';
import customer from './customer/Customer';
import consumer from './customer/Consumer';
import promotion from './product/Promotion';
import productPrice from './product/ProductPrice';
import cost from './product/Cost';
import dimension from './product/Dimension';
import warranty from './product/Warranty';
import location from './product/Location';
import productCategory from './product/ProductCategory';
import productType from './product/ProductType';
import brand from './product/Brand';
import product from './product/Product';
import movementJournal from './product/MovementJournal';
import agent from './agent/Agent';
import timeslot from './service/Timeslot';
import holiday from './service/Holiday';
import appointment from './service/Appointment';
import membership from './customer/Membership';
import transaction from './payment/Transaction';
import invoice from './payment/Invoice';
import coupon from './payment/Coupon';
import setting from './network/Setting';
import serviceMovingJournal from './service/ServiceMovingJournal';
import notification from './admin/Notification';
import dashboard from './admin/Dashboard';
import configuration from './admin/Configuration';
import permission from './admin/Permission';
import bank from './network/Bank';
import usageNotification from './network/UsageNotification';
import shoppingCart from './network/ShoppingCart';
import frewiePlan from './frewie/FrewiePlan';
import frewiePlatform from './frewie/FrewiePlatform';
import frewieSerialNumber from './frewie/FrewieSerialNumber';
import country from './product/Country';
import wallet from './payment/Wallet';
import frewieProductCountry from './frewie/FrewieProductCountry';
import recommend from './product/Recommend';
import freewie from './network/Freewie';
import bestSelling from './product/BestSelling';

const appReducer = history =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    account,
    admin,
    modal,
    file,
    log,
    notification,
    dashboard,
    configuration,
    permission,
    wallet,

    company,
    currency,
    uom,
    paymentTerm,
    tax,
    purchaseSetup,
    purchaseOrder,
    saleOrder,
    invitation,
    coupon,
    setting,
    usageNotification,

    customer,
    consumer,

    cost,
    promotion,
    productPrice,
    dimension,
    warranty,
    location,
    productCategory,
    productType,
    brand,
    product,
    movementJournal,

    agent,
    timeslot,
    holiday,
    appointment,
    membership,
    transaction,
    invoice,
    serviceMovingJournal,
    bank,
    shoppingCart,
    moreDirectory,
    offerGroup,
    bestSelling,
    partnerOffer,

    frewiePlan,
    frewiePlatform,
    frewieSerialNumber,
    country,
    frewieProductCountry,
    recommend,
    freewie
  });

const initialState = appReducer({}, {});

const rootReducer = history => (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = initialState;
  }

  return appReducer(history)(state, action);
};

export default rootReducer;
