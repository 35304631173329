import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';

export const FETCH_BEST_SELLING_REQUEST = 'FETCH_BEST_SELLING_REQUEST';
export const FETCH_BEST_SELLING_SUCCESS = 'FETCH_BEST_SELLING_SUCCESS';
export const FETCH_BEST_SELLING_ERROR = 'FETCH_BEST_SELLING_ERROR';


export const GET_BEST_SELLING_DETAIL_REQUEST =
    'GET_BEST_SELLING_DETAIL_REQUEST';
export const GET_BEST_SELLING_DETAIL_SUCCESS =
    'GET_BEST_SELLING_DETAIL_SUCCESS';
export const GET_BEST_SELLING_DETAIL_ERROR = 'GET_BEST_SELLING_DETAIL_ERROR';

export const CREATE_BEST_SELLING_REQUEST = 'CREATE_BEST_SELLING_REQUEST';
export const CREATE_BEST_SELLING_SUCCESS = 'CREATE_BEST_SELLING_SUCCESS';
export const CREATE_BEST_SELLING_ERROR = 'CREATE_BEST_SELLING_ERROR';

export const UPDATE_BEST_SELLING_REQUEST = 'UPDATE_BEST_SELLING_REQUEST';
export const UPDATE_BEST_SELLING_SUCCESS = 'UPDATE_BEST_SELLING_SUCCESS';
export const UPDATE_BEST_SELLING_ERROR = 'UPDATE_BEST_SELLING_ERROR';

export const DELETE_BEST_SELLING_REQUEST = 'DELETE_BEST_SELLING_REQUEST';
export const DELETE_BEST_SELLING_SUCCESS = 'DELETE_BEST_SELLING_SUCCESS';
export const DELETE_BEST_SELLING_ERROR = 'DELETE_BEST_SELLING_ERROR';

export const fetchBestSelling = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_BEST_SELLING_REQUEST,
                FETCH_BEST_SELLING_SUCCESS,
                FETCH_BEST_SELLING_ERROR,
            ],
            callAPI: () => ApiClient.post('product/BestSelling/Search', data),
            payload: { data },
        }),
    );
};

export const getBestSellingDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_BEST_SELLING_DETAIL_REQUEST,
                GET_BEST_SELLING_DETAIL_SUCCESS,
                GET_BEST_SELLING_DETAIL_ERROR,
            ],
            callAPI: () => ApiClient.get(`product/BestSelling/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const createBestSelling = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                CREATE_BEST_SELLING_REQUEST,
                CREATE_BEST_SELLING_SUCCESS,
                CREATE_BEST_SELLING_ERROR,
            ],
            callAPI: () => ApiClient.post('product/BestSelling', data),
            payload: { data, formId },
        }),
    );
};


export const updateBestSelling = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                UPDATE_BEST_SELLING_REQUEST,
                UPDATE_BEST_SELLING_SUCCESS,
                UPDATE_BEST_SELLING_ERROR,
            ],
            callAPI: () => ApiClient.put(`product/BestSelling/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const deleteBestSelling = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                DELETE_BEST_SELLING_REQUEST,
                DELETE_BEST_SELLING_SUCCESS,
                DELETE_BEST_SELLING_ERROR,
            ],
            callAPI: () => ApiClient.delete(`product/BestSelling/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_BEST_SELLING_REQUEST:
        case GET_BEST_SELLING_DETAIL_REQUEST:
        case CREATE_BEST_SELLING_REQUEST:
        case UPDATE_BEST_SELLING_REQUEST:
        case DELETE_BEST_SELLING_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_BEST_SELLING_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_BEST_SELLING_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_BEST_SELLING_SUCCESS:
        case UPDATE_BEST_SELLING_SUCCESS:
        case DELETE_BEST_SELLING_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_BEST_SELLING_ERROR:
        case GET_BEST_SELLING_DETAIL_ERROR:
        case CREATE_BEST_SELLING_ERROR:
        case UPDATE_BEST_SELLING_ERROR:
        case DELETE_BEST_SELLING_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
