import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';

export const FETCH_PRODUCT_OPTIONS_REQUEST = 'FETCH_PRODUCT_OPTIONS_REQUEST';
export const FETCH_PRODUCT_OPTIONS_SUCCESS = 'FETCH_PRODUCT_OPTIONS_SUCCESS';
export const FETCH_PRODUCT_OPTIONS_ERROR = 'FETCH_PRODUCT_OPTIONS_ERROR';

export const FETCH_DISTINCT_DATA_OPTIONS_SUCCESS = 'FETCH_DISTINCT_DATA_OPTIONS_SUCCESS';
export const FETCH_DISTINCT_DAYS_OPTIONS_SUCCESS = 'FETCH_DISTINCT_DAYS_OPTIONS_SUCCESS';

export const FETCH_ALL_PRODUCT_OPTIONS_REQUEST = 'FETCH_ALL_PRODUCT_OPTIONS_REQUEST';
export const FETCH_ALL_PRODUCT_OPTIONS_SUCCESS = 'FETCH_ALL_PRODUCT_OPTIONS_SUCCESS';
export const FETCH_ALL_PRODUCT_OPTIONS_ERROR = 'FETCH_ALL_PRODUCT_OPTIONS_ERROR';

export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_ERROR = 'GET_PRODUCT_DETAIL_ERROR';

export const GET_PRODUCT_TO_EDIT_REQUEST = 'GET_PRODUCT_TO_EDIT_REQUEST';
export const GET_PRODUCT_TO_EDIT_SUCCESS = 'GET_PRODUCT_TO_EDIT_SUCCESS';
export const GET_PRODUCT_TO_EDIT_ERROR = 'GET_PRODUCT_TO_EDIT_ERROR';

export const SEARCH_BY_DISTRIBUTOR_REQUEST = 'SEARCH_BY_DISTRIBUTOR_REQUEST';
export const SEARCH_BY_DISTRIBUTOR_SUCCESS = 'SEARCH_BY_DISTRIBUTOR_SUCCESS';
export const SEARCH_BY_DISTRIBUTOR_ERROR = 'SEARCH_BY_DISTRIBUTOR_ERROR';

export const SEARCH_BY_DATAPLAN_REQUEST = 'SEARCH_BY_DATAPLAN_REQUEST';
export const SEARCH_BY_DATAPLAN_SUCCESS = 'SEARCH_BY_DATAPLAN_SUCCESS';
export const SEARCH_BY_DATAPLAN_ERROR = 'SEARCH_BY_DATAPLAN_ERROR';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

export const UPDATE_VARIANT_REQUEST = 'UPDATE_VARIANT_REQUEST';
export const UPDATE_VARIANT_SUCCESS = 'UPDATE_VARIANT_SUCCESS';
export const UPDATE_VARIANT_ERROR = 'UPDATE_VARIANT_ERROR';

export const FETCH_VARIANT_OPTIONS_REQUEST = 'FETCH_VARIANT_OPTIONS_REQUEST';
export const FETCH_VARIANT_OPTIONS_SUCCESS = 'FETCH_VARIANT_OPTIONS_SUCCESS';
export const FETCH_VARIANT_OPTIONS_ERROR = 'FETCH_VARIANT_OPTIONS_ERROR';

export const GET_PRODUCT_LOCATIONS_REQUEST = 'GET_PRODUCT_LOCATIONS_REQUEST';
export const GET_PRODUCT_LOCATIONS_SUCCESS = 'GET_PRODUCT_LOCATIONS_SUCCESS';
export const GET_PRODUCT_LOCATIONS_ERROR = 'GET_PRODUCT_LOCATIONS_ERROR';

export const FETCH_PRODUCT_DIMENSION_OPTIONS_REQUEST = 'FETCH_PRODUCT_DIMENSION_OPTIONS_REQUEST';
export const FETCH_PRODUCT_DIMENSION_OPTIONS_SUCCESS = 'FETCH_PRODUCT_DIMENSION_OPTIONS_SUCCESS';
export const FETCH_PRODUCT_DIMENSION_OPTIONS_ERROR = 'FETCH_PRODUCT_DIMENSION_OPTIONS_SETUP_ERROR';

export const GET_PRODUCT_AFFECTED_BY_UOM_REQUEST = 'GET_PRODUCT_AFFECTED_BY_UOM_REQUEST';
export const GET_PRODUCT_AFFECTED_BY_UOM_SUCCESS = 'GET_PRODUCT_AFFECTED_BY_UOM_SUCCESS';
export const GET_PRODUCT_AFFECTED_BY_UOM_ERROR = 'GET_PRODUCT_AFFECTED_BY_UOM_ERROR';

export const UPDATE_COST_BY_UOM_REQUEST = 'UPDATE_COST_BY_UOM_REQUEST';
export const UPDATE_COST_BY_UOM_SUCCESS = 'UPDATE_COST_BY_UOM_SUCCESS';
export const UPDATE_COST_BY_UOM_ERROR = 'UPDATE_COST_BY_UOM_ERROR';

export const CALCULATE_PRICE_REQUEST = 'CALCULATE_PRICE_REQUEST';
export const CALCULATE_PRICE_SUCCESS = 'CALCULATE_PRICE_SUCCESS';
export const CALCULATE_STOCK_SUCCESS = 'CALCULATE_STOCK_SUCCESS';
export const CALCULATE_PRICE_ERROR = 'CALCULATE_PRICE_ERROR';

export const FETCH_FREWIE_PLAN_OPTION_REQUEST = 'FETCH_FREWIE_PLAN_OPTION_REQUEST';
export const FETCH_FREWIE_PLAN_OPTION_SUCCESS = 'FETCH_FREWIE_PLAN_OPTION_SUCCESS';
export const FETCH_FREWIE_PLAN_OPTION_ERROR = 'FETCH_FREWIE_PLAN_OPTION_ERROR';

export const FETCH_DATAPLAN_DETAILS_REQUEST = 'FETCH_DATAPLAN_DETAILS_REQUEST';
export const FETCH_DATAPLAN_DETAILS_SUCCESS = 'FETCH_DATAPLAN_DETAILS_SUCCESS';
export const FETCH_DATAPLAN_DETAILS_ERROR = 'FETCH_DATAPLAN_DETAILS_ERROR';

export const fetchProducts = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_ERROR],
      callAPI: () => ApiClient.post('product/Product/Search', data),
      payload: { data },
    }),
  );
};

export const exportDataPlanProductVariant = (providerId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_ERROR],
      callAPI: () => ApiClient.get(`product/FrewieProductPlan/ExportDataPlanWithVariant/${providerId}`,
        {
          timeout: 600000,
        }),
      payload: {},
    }),
  );
};


export const searchProductsOfDistributor = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [SEARCH_BY_DISTRIBUTOR_REQUEST, SEARCH_BY_DISTRIBUTOR_SUCCESS, SEARCH_BY_DISTRIBUTOR_ERROR],
      callAPI: () => ApiClient.post('product/Product/SearchByDistributor', data),
      payload: { data },
    }),
  );
};

export const fetchFrewiePlanOptions = (productId, providerId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_FREWIE_PLAN_OPTION_REQUEST, FETCH_FREWIE_PLAN_OPTION_SUCCESS, FETCH_FREWIE_PLAN_OPTION_ERROR],
      callAPI: () => ApiClient.get(`product/Product/GetFrewiePlanSelectList/${productId}/${providerId}`),
      payload: {},
    }),
  );
};

export const fetchPlanDetails = (planId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_DATAPLAN_DETAILS_REQUEST, FETCH_DATAPLAN_DETAILS_SUCCESS, FETCH_DATAPLAN_DETAILS_ERROR],
      callAPI: () => ApiClient.get(`product/FrewieProductPlan/GetDetailByPlanId/${planId}`),
      payload: {},
    }),
  );
};

export const variantSearch = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [SEARCH_BY_DATAPLAN_REQUEST, SEARCH_BY_DATAPLAN_SUCCESS, SEARCH_BY_DATAPLAN_ERROR],
      callAPI: () => ApiClient.post('product/ProductVariant/SearchRegional', data),
      payload: { data },
    }),
  );
};

export const searchDataPlan = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [SEARCH_BY_DATAPLAN_REQUEST, SEARCH_BY_DATAPLAN_SUCCESS, SEARCH_BY_DATAPLAN_ERROR],
      // callAPI: () => axios.post('http://localhost:5000/api/Product/SearchDataPlan', data),
      callAPI: () => ApiClient.post('product/Product/SearchDataPlan', data),
      payload: { data },
    }),
  );
};

export const fetchProductOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PRODUCT_OPTIONS_REQUEST, FETCH_PRODUCT_OPTIONS_SUCCESS, FETCH_PRODUCT_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('product/Product/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchDistinctDataOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PRODUCT_OPTIONS_REQUEST, FETCH_DISTINCT_DATA_OPTIONS_SUCCESS, FETCH_PRODUCT_OPTIONS_ERROR],
      callAPI: () => ApiClient.get(`product/ProductVariant/GetDistinctDataSelectList/${id}`),
      payload: {},
    }),
  );
};

export const fetchDistinctDaysOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PRODUCT_OPTIONS_REQUEST, FETCH_DISTINCT_DAYS_OPTIONS_SUCCESS, FETCH_PRODUCT_OPTIONS_ERROR],
      callAPI: () => ApiClient.get(`product/ProductVariant/GetDistinctDaysSelectList/${id}`),
      payload: {},
    }),
  );
};

export const fetchAllProductOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_ALL_PRODUCT_OPTIONS_REQUEST, FETCH_ALL_PRODUCT_OPTIONS_SUCCESS, FETCH_ALL_PRODUCT_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('product/Product/GetAllSelectList'),
      payload: {},
    }),
  );
};

export const getProductDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_PRODUCT_DETAIL_REQUEST, GET_PRODUCT_DETAIL_SUCCESS, GET_PRODUCT_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`product/Product/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getProductToEdit = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_PRODUCT_TO_EDIT_REQUEST, GET_PRODUCT_TO_EDIT_SUCCESS, GET_PRODUCT_TO_EDIT_ERROR],
      callAPI: () => ApiClient.get(`product/Product/GetDetailForEdit/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createProduct = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_ERROR],
      callAPI: () => ApiClient.post('product/Product', data),
      payload: { data, formId },
    }),
  );
};

export const updateProduct = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_ERROR],
      callAPI: () => ApiClient.put(`product/Product/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteProduct = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_ERROR],
      callAPI: () => ApiClient.delete(`product/Product/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const updateVariant = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_VARIANT_REQUEST, UPDATE_VARIANT_SUCCESS, UPDATE_VARIANT_ERROR],
      callAPI: () => ApiClient.put(`product/Product/UpdateVariant/${id}`, data),
      payload: { data: { id, ...data }, formId },
    }),
  );
};


export const deleteVariant = (ids) => dispatch => {
  const queryParams = ids.map(id => `ids=${id}`).join('&');
  return new Promise((resolve, reject) => {
    dispatch({
      types: [DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_ERROR],
      callAPI: () => ApiClient.delete(`product/Product/DeleteVariant?${queryParams}`),
      payload: { ids },
    });
    resolve(); // Resolve the Promise once the action is dispatched
  });
};



export const createVariant = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_ERROR],
      callAPI: () => ApiClient.post(`product/Product/CreateVariant`, data),
      payload: { data, formId },
    }),
  );
};

export const fetchVariantDiscountValueOptions = (discountValue, productId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_VARIANT_OPTIONS_REQUEST, FETCH_VARIANT_OPTIONS_SUCCESS, FETCH_VARIANT_OPTIONS_ERROR],
      callAPI: () => {
        return ApiClient.get(`product/ProductVariant/GetDiscountValueSelectList/${discountValue}/${productId}`);

      },
      payload: {},
    }),
  );
};

export const fetchVariantDiscountOptions = (offerGroupId, productId) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_VARIANT_OPTIONS_REQUEST, FETCH_VARIANT_OPTIONS_SUCCESS, FETCH_VARIANT_OPTIONS_ERROR],
      callAPI: () => {
        return ApiClient.get(`product/ProductVariant/GetDiscountSelectList/${offerGroupId}/${productId}`);

      },
      payload: {},
    }),
  );
};

export const fetchVariantOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_VARIANT_OPTIONS_REQUEST, FETCH_VARIANT_OPTIONS_SUCCESS, FETCH_VARIANT_OPTIONS_ERROR],
      callAPI: () => {
        if (id) {
          return ApiClient.get(`product/ProductVariant/GetSelectList/${id}`);
        } else {
          return ApiClient.get(`product/ProductVariant/GetSelectList`);
        }
      },
      payload: {},
    }),
  );
};

export const fetchFilterInternalViewVariantOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_VARIANT_OPTIONS_REQUEST, FETCH_VARIANT_OPTIONS_SUCCESS, FETCH_VARIANT_OPTIONS_ERROR],
      callAPI: () => {
        if (id) {
          return ApiClient.get(`product/ProductVariant/GetSelectList/${id}`);
        } else {
          return ApiClient.get(`product/ProductVariant/GetInternalViewSelectList`);
        }
      },
      payload: {},
    }),
  );
};

export const getProductLocations = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_PRODUCT_LOCATIONS_REQUEST, GET_PRODUCT_LOCATIONS_SUCCESS, GET_PRODUCT_LOCATIONS_ERROR],
      callAPI: () => ApiClient.get(`product/Product/GetQuantity/${id}`),
      payload: { id },
    }),
  );
};

export const fetchProductDimensionOptions = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_PRODUCT_DIMENSION_OPTIONS_REQUEST,
        FETCH_PRODUCT_DIMENSION_OPTIONS_SUCCESS,
        FETCH_PRODUCT_DIMENSION_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get(`product/ProductDimension/GetSelectList/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getProductsAffectedByUom = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        GET_PRODUCT_AFFECTED_BY_UOM_REQUEST,
        GET_PRODUCT_AFFECTED_BY_UOM_SUCCESS,
        GET_PRODUCT_AFFECTED_BY_UOM_ERROR,
      ],
      callAPI: () => ApiClient.post(`product/ProductCost/GetProductsAffectedByUom`, data),
      payload: { data, formId },
    }),
  );
};

export const updateCostByUom = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [UPDATE_COST_BY_UOM_REQUEST, UPDATE_COST_BY_UOM_SUCCESS, UPDATE_COST_BY_UOM_ERROR],
      callAPI: () => ApiClient.put(`product/ProductCost/UpdateByUom`, data),
      payload: { data },
    }),
  );
};

export const calculatePrice = (id, data) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [CALCULATE_PRICE_REQUEST, CALCULATE_PRICE_SUCCESS, CALCULATE_PRICE_ERROR],
      callAPI: () => ApiClient.post(`product/ProductPrice/GetPrice/${id}`, data),
      payload: { id, data },
    }),
  );
};

export const checkProductVariantStock = (productId, variantId, quantity) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [CALCULATE_PRICE_REQUEST, CALCULATE_STOCK_SUCCESS, CALCULATE_PRICE_ERROR],
      callAPI: () => ApiClient.get(`product/Product/CheckProductVariantStock/${productId}/${variantId}/${quantity}`),
      payload: {},
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
    case SEARCH_BY_DISTRIBUTOR_REQUEST:
    case SEARCH_BY_DATAPLAN_REQUEST:
    case FETCH_PRODUCT_OPTIONS_REQUEST:
    case FETCH_ALL_PRODUCT_OPTIONS_REQUEST:
    case GET_PRODUCT_DETAIL_REQUEST:
    case CREATE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case DELETE_PRODUCT_REQUEST:
    case UPDATE_VARIANT_REQUEST:
    case FETCH_VARIANT_OPTIONS_REQUEST:
    case GET_PRODUCT_LOCATIONS_REQUEST:
    case FETCH_PRODUCT_DIMENSION_OPTIONS_REQUEST:
    case GET_PRODUCT_AFFECTED_BY_UOM_REQUEST:
    case UPDATE_COST_BY_UOM_REQUEST:
    case FETCH_DATAPLAN_DETAILS_REQUEST:
    case GET_PRODUCT_TO_EDIT_REQUEST:
    case FETCH_FREWIE_PLAN_OPTION_REQUEST:
    case CALCULATE_PRICE_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_DISTINCT_DATA_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, distinctDataOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_DISTINCT_DAYS_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, distinctDaysOptions: response.data.data },
        response,
        successMessage,
      };


    case FETCH_ALL_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, allOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_FREWIE_PLAN_OPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, frewiePlanOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_VARIANT_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, variantOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_PRODUCT_DIMENSION_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, productDimensionOptions: response.data.data },
        response,
        successMessage,
      };

    case FETCH_PRODUCT_SUCCESS:
    case FETCH_DATAPLAN_DETAILS_SUCCESS:
    case SEARCH_BY_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...response.data.data,
        },
        response,
        successMessage,
      };

    case SEARCH_BY_DATAPLAN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...response.data.data,
        },
        response,
        successMessage,
      };

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          itemDetail: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_PRODUCT_TO_EDIT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          itemEdit: response.data.data,
        },
        response,
        successMessage,
      };

    case CALCULATE_STOCK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          variantBalance: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_PRODUCT_LOCATIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          productLocations: response.data.data,
        },
        response,
        successMessage,
      };
    case GET_PRODUCT_AFFECTED_BY_UOM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          products: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
    case DELETE_PRODUCT_SUCCESS:
    case UPDATE_VARIANT_SUCCESS:
    case UPDATE_COST_BY_UOM_SUCCESS:
    case CALCULATE_PRICE_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_PRODUCT_ERROR:
    case SEARCH_BY_DISTRIBUTOR_ERROR:
    case SEARCH_BY_DATAPLAN_ERROR:
    case FETCH_PRODUCT_OPTIONS_ERROR:
    case FETCH_ALL_PRODUCT_OPTIONS_ERROR:
    case GET_PRODUCT_DETAIL_ERROR:
    case CREATE_PRODUCT_ERROR:
    case UPDATE_PRODUCT_ERROR:
    case FETCH_DATAPLAN_DETAILS_ERROR:
    case DELETE_PRODUCT_ERROR:
    case FETCH_FREWIE_PLAN_OPTION_ERROR:
    case UPDATE_VARIANT_ERROR:
    case FETCH_VARIANT_OPTIONS_ERROR:
    case GET_PRODUCT_LOCATIONS_ERROR:
    case FETCH_PRODUCT_DIMENSION_OPTIONS_ERROR:
    case GET_PRODUCT_AFFECTED_BY_UOM_ERROR:
    case GET_PRODUCT_AFFECTED_BY_UOM_ERROR:
    case UPDATE_COST_BY_UOM_ERROR:
    case GET_PRODUCT_TO_EDIT_ERROR:
    case CALCULATE_PRICE_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
