import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import id from 'date-fns/locale/id/index';

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR';

export const FETCH_INVOICE_DETAIL_REQUEST = 'FETCH_INVOICE_DETAIL_REQUEST';
export const FETCH_INVOICE_DETAIL_SUCCESS = 'FETCH_INVOICE_DETAIL_SUCCESS';
export const FETCH_INVOICE_DETAIL_ERROR = 'FETCH_INVOICE_DETAIL_ERROR';

export const GET_INVOICE_DETAIL_REQUEST = 'GET_INVOICE_DETAIL_REQUEST';
export const GET_INVOICE_DETAIL_SUCCESS = 'GET_INVOICE_DETAIL_SUCCESS';
export const GET_INVOICE_DETAIL_ERROR = 'GET_INVOICE_DETAIL_ERROR';

export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR';

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'DELETE_INVOICE_ERROR';

export const FETCH_COUPON_OPTIONS_REQUEST =
    'FETCH_COUPON_OPTIONS_REQUEST';
export const FETCH_COUPON_OPTIONS_SUCCESS =
    'FETCH_COUPON_OPTIONS_SUCCESS';
export const FETCH_COUPON_OPTIONS_ERROR =
    'FETCH_COUPON_OPTIONS_ERROR';


export const fetchCoupons = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_ERROR],
            callAPI: () => ApiClient.post('payment/Coupons/Search', data),
            payload: { data },
        }),
    );
};

export const fetchCouponTransactions = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_ERROR],
            callAPI: () => ApiClient.post('payment/CouponTransactions/Search', data),
            payload: { data },
        }),
    );
};

export const createCoupon = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [CREATE_INVOICE_REQUEST, CREATE_INVOICE_SUCCESS, CREATE_INVOICE_ERROR],
            callAPI: () => ApiClient.post('payment/Coupons', data),
            payload: { data, formId },
        }),
    );
};

export const updateCoupon = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [UPDATE_INVOICE_REQUEST, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_ERROR],
            callAPI: () => ApiClient.put(`payment/Coupons/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const getCouponDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [GET_INVOICE_DETAIL_REQUEST, GET_INVOICE_DETAIL_SUCCESS, GET_INVOICE_DETAIL_ERROR],
            callAPI: () => ApiClient.get(`payment/Coupons/GetDetail/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const fetchCouponOptions = () => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_COUPON_OPTIONS_REQUEST,
                FETCH_COUPON_OPTIONS_SUCCESS,
                FETCH_COUPON_OPTIONS_ERROR,
            ],
            callAPI: () => ApiClient.get('payment/Coupons/GetSelectList'),
            payload: {},
        }),
    );
};

export const deleteCoupon = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [DELETE_INVOICE_REQUEST, DELETE_INVOICE_SUCCESS, DELETE_INVOICE_ERROR],
            callAPI: () => ApiClient.delete(`payment/Coupons/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_INVOICES_REQUEST:
        case FETCH_COUPON_OPTIONS_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };


        case FETCH_INVOICES_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case FETCH_COUPON_OPTIONS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    options: response.data.data,
                },
                response,
                successMessage,
            };

        case GET_INVOICE_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_INVOICE_SUCCESS:
        case UPDATE_INVOICE_SUCCESS:
        case DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_INVOICES_ERROR:
        case CREATE_INVOICE_ERROR:
        case UPDATE_INVOICE_ERROR:
        case FETCH_COUPON_OPTIONS_ERROR:
        case DELETE_INVOICE_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
