import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';

export const FETCH_RECOMMEND_REQUEST = 'FETCH_RECOMMEND_REQUEST';
export const FETCH_RECOMMEND_SUCCESS = 'FETCH_RECOMMEND_SUCCESS';
export const FETCH_RECOMMEND_ERROR = 'FETCH_RECOMMEND_ERROR';


export const GET_RECOMMEND_DETAIL_REQUEST =
    'GET_RECOMMEND_DETAIL_REQUEST';
export const GET_RECOMMEND_DETAIL_SUCCESS =
    'GET_RECOMMEND_DETAIL_SUCCESS';
export const GET_RECOMMEND_DETAIL_ERROR = 'GET_RECOMMEND_DETAIL_ERROR';

export const CREATE_RECOMMEND_REQUEST = 'CREATE_RECOMMEND_REQUEST';
export const CREATE_RECOMMEND_SUCCESS = 'CREATE_RECOMMEND_SUCCESS';
export const CREATE_RECOMMEND_ERROR = 'CREATE_RECOMMEND_ERROR';

export const UPDATE_RECOMMEND_REQUEST = 'UPDATE_RECOMMEND_REQUEST';
export const UPDATE_RECOMMEND_SUCCESS = 'UPDATE_RECOMMEND_SUCCESS';
export const UPDATE_RECOMMEND_ERROR = 'UPDATE_RECOMMEND_ERROR';

export const DELETE_RECOMMEND_REQUEST = 'DELETE_RECOMMEND_REQUEST';
export const DELETE_RECOMMEND_SUCCESS = 'DELETE_RECOMMEND_SUCCESS';
export const DELETE_RECOMMEND_ERROR = 'DELETE_RECOMMEND_ERROR';

export const fetchRecommend = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_RECOMMEND_REQUEST,
                FETCH_RECOMMEND_SUCCESS,
                FETCH_RECOMMEND_ERROR,
            ],
            callAPI: () => ApiClient.post('product/Recommend/Search', data),
            payload: { data },
        }),
    );
};

export const getRecommendDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_RECOMMEND_DETAIL_REQUEST,
                GET_RECOMMEND_DETAIL_SUCCESS,
                GET_RECOMMEND_DETAIL_ERROR,
            ],
            callAPI: () => ApiClient.get(`product/Recommend/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const createRecommend = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                CREATE_RECOMMEND_REQUEST,
                CREATE_RECOMMEND_SUCCESS,
                CREATE_RECOMMEND_ERROR,
            ],
            callAPI: () => ApiClient.post('product/Recommend', data),
            payload: { data, formId },
        }),
    );
};


export const updateRecommend = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                UPDATE_RECOMMEND_REQUEST,
                UPDATE_RECOMMEND_SUCCESS,
                UPDATE_RECOMMEND_ERROR,
            ],
            callAPI: () => ApiClient.put(`product/Recommend/Update/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const deleteRecommend = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                DELETE_RECOMMEND_REQUEST,
                DELETE_RECOMMEND_SUCCESS,
                DELETE_RECOMMEND_ERROR,
            ],
            callAPI: () => ApiClient.delete(`product/Recommend/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_RECOMMEND_REQUEST:
        case GET_RECOMMEND_DETAIL_REQUEST:
        case CREATE_RECOMMEND_REQUEST:
        case UPDATE_RECOMMEND_REQUEST:
        case DELETE_RECOMMEND_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_RECOMMEND_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_RECOMMEND_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_RECOMMEND_SUCCESS:
        case UPDATE_RECOMMEND_SUCCESS:
        case DELETE_RECOMMEND_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_RECOMMEND_ERROR:
        case GET_RECOMMEND_DETAIL_ERROR:
        case CREATE_RECOMMEND_ERROR:
        case UPDATE_RECOMMEND_ERROR:
        case DELETE_RECOMMEND_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
